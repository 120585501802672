import { useIsClient } from "@uidotdev/usehooks";
import { useRouter } from "next/router";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import {
  updateFetchingJobListStatus,
  updateHoverJob,
  updateSearchFeedback,
} from "../../../redux/actions/job_action";
import AccordionJobCard from "../AccordionJobCard/AccordionJobCard";
import {
  ChevronLeftIconStyled,
  ChevronRightIconStyled,
  EmptyContainer,
  IconButtonStyled,
  JobListContainer,
  PageNumber,
  PaginationNumberBox,
  Wrapper,
} from "./styles";
function AccordionJobList(props) {
  const { jobs } = props;

  return (
    <Wrapper>
      <JobListContainer>
        {jobs?.map((job) => (
          <AccordionJobCard key={job.id} job={job} />
        ))}
      </JobListContainer>
    </Wrapper>
  );
}

function Pagination(props) {
  const dispatch = useDispatch();
  const router = useRouter();
  const isClient = useIsClient();

  const jobPageInfo = useSelector((state) => state.jobs.jobPageInfoV1);
  const currentPage = useSelector((state) => state.jobs.jobCurrentPage);
  const submitSearchFeedback = useSelector(
    (state) => state.jobs.searchFeedback.feedbackSubmit
  );

  function parsePageUrl(pageDirection) {
    const query = router.query;
    const { filter, ...queryParams } = query;
    const { page, ...rest } = queryParams;

    const slug = filter.join("/");

    const newPage =
      pageDirection === "next" ? currentPage + 1 : currentPage - 1;
    const isFirstPage = newPage === 1 ? true : false;

    router.push(
      {
        pathname: slug,
        query: {
          ...rest,
          ...(!isFirstPage && { page: newPage }),
        },
      },
      undefined,
      { shallow: true }
    );
  }

  function jobListNextPage() {
    sendTrackingEvent({
      event: "CE_clicked-jobs-page",
      "page-number": currentPage,
      action: "next",
    });

    parsePageUrl("next");
    dispatch(updateFetchingJobListStatus(true));

    if (submitSearchFeedback) {
      dispatch(updateSearchFeedback({ feedbackSubmit: true, showCard: false }));
    }
    dispatch(updateHoverJob({}));
    window.scrollTo(0, 0);
  }

  function jobListPreviousPage() {
    sendTrackingEvent({
      event: "CE_clicked-jobs-page",
      "page-number": currentPage,
      action: "previous",
    });

    parsePageUrl("previous");
    dispatch(updateFetchingJobListStatus(true));

    if (submitSearchFeedback) {
      dispatch(updateSearchFeedback({ feedbackSubmit: true, showCard: false }));
    }
    dispatch(updateHoverJob({}));
    window.scrollTo(0, 0);
  }

  return (
    <EmptyContainer
      sx={{ width: "100%", marginTop: "25px" }}
      container
      direction="row"
      justifyContent="center"
    >
      {jobPageInfo?.hasPreviousPage ? (
        <Fragment>
          <IconButtonStyled
            $margin_right="true"
            $pagination="true"
            disableRipple
            onClick={() => jobListPreviousPage()}
            disabled={!jobPageInfo?.hasPreviousPage}
          >
            {isClient && <ChevronLeftIconStyled />}
          </IconButtonStyled>
          <PaginationNumberBox $non_focus="true">
            <PageNumber variant="subtitle2">{currentPage - 1}</PageNumber>
          </PaginationNumberBox>
        </Fragment>
      ) : null}

      <PaginationNumberBox $margin="true">
        <PageNumber variant="subtitle2">{currentPage}</PageNumber>
      </PaginationNumberBox>

      {jobPageInfo?.hasNextPage && (
        <Fragment>
          <PaginationNumberBox $non_focus="true">
            <PageNumber variant="subtitle2">{currentPage + 1}</PageNumber>
          </PaginationNumberBox>
          <IconButtonStyled
            $margin_left="true"
            $pagination="true"
            disableRipple
            onClick={() => jobListNextPage()}
            disabled={!jobPageInfo?.hasNextPage}
          >
            {isClient && <ChevronRightIconStyled />}
          </IconButtonStyled>
        </Fragment>
      )}
    </EmptyContainer>
  );
}

export default AccordionJobList;
