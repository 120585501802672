import useMediaQuery from "@mui/material/useMediaQuery";
import { useIsClient } from "@uidotdev/usehooks";
import moment from "moment";
import { useMemo } from "react";
import { InView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import Color from "../../../assets/colors";
import { formatSalary } from "../../../helpers/conditional_rendering";
import {
  getCompanyDetails,
  getCompanyLogoImage,
} from "../../../helpers/data_management";
import { updateSelectedJob } from "../../../redux/actions/job_action";
import {
  updateShowSignInModalStatus,
  updateSignUpOrigin,
} from "../../../redux/actions/navbar_action";
import { checkAuthentication } from "../../../redux/actions/user_action";
import { SharedSGTooltip } from "../../shared/SharedSGJobTooltip/SharedSGJobTooltip";
import {
  ArrowIconStyled,
  BookmarkButton,
  BottomContainer,
  BoxContainer,
  CircularProgressStyled,
  CompanyLogoStyled,
  CompanyNameStyled,
  CustomTooltip,
  DividerStyled,
  DollarIcon,
  GlobalChip,
  GlobalIcon,
  Greenthang,
  HighlightChip,
  HighlightIcon,
  InnerWrapperContainer,
  JobCardContainer,
  JobTitleStyled,
  JobTypeChip,
  KeywordChip,
  LinkContainer,
  LocationIcon,
  MobileApplyButton,
  MobileChipContainer,
  MobileDetailChip,
  MobileKeywordContainer,
  PostedTextStyled,
  SaveFilledIcon,
  SaveOutlineIcon,
  SidebarColor,
  SpotlightChip,
  SpotlightIcon,
  TextStyled,
  TopContainer,
} from "./styles";

function AccordionJobCard(props) {
  const { job } = props;

  const isMobile = useMediaQuery("(max-width: 640px)");
  const isClient = useIsClient();

  const boosted = false;
  const jobSlotUpgrade = false;
  const chipExist = false;
  const isDesktop = false;
  const isLoggedIn = false;
  const origin = "";
  const currentId = "";
  const onFollowJobClicked = () => {};
  const unSaveJob = () => {};

  function getTime(job) {
    let activeAtDate = job?.activeAt || job?.active_at;
    let jobActiveAt = moment(activeAtDate, "YYYYMMDD");
    let timeNow = moment(moment(), "YYYYMMDD");

    const time =
      timeNow.diff(jobActiveAt, "months") > 3
        ? null
        : timeNow.diff(jobActiveAt, "months") > 1
        ? timeNow.diff(jobActiveAt, "months") + " months ago"
        : moment(activeAtDate).fromNow();

    return time;
  }

  /* MEMO SECTION */
  const companyDetails = useMemo(() => {
    return getCompanyDetails(job);
  }, [job?.company, job?.crossBorderData]);
  const companyName = companyDetails?.name;

  const companyLogo = useMemo(() => {
    return getCompanyLogoImage(companyDetails);
  }, [companyDetails]);

  const jobPostedTime = useMemo(() => {
    return getTime(job) !== null ? getTime(job) : null;
  }, [job]);

  return (
    <LinkContainer
      href={`/jobs/${job?.slug}`}
      onClick={(event) => clickHandler(event, job)}
    >
      {/* OUTER MOST CONTAINER OTHER THAN THE LINK COMPONENT */}
      <JobCardContainer>
        {/* LEFT HIGHLIGHT BANNER */}
        {boosted ? (
          <SidebarColor
            $spotlight={job?.spotlight || jobSlotUpgrade}
            $highlight={job?.boosted}
          />
        ) : null}
        {/* MAIN JOB CARD SECTION */}
        <InnerWrapperContainer
          container
          flexDirection={"row"}
          width={"100%"}
          position={"relative"}
        >
          <CustomTooltip followCursor title={"Click to view full job details"}>
            <InnerWrapperContainer className="responsive">
              <TopContainer $boosted={boosted}>
                {chipExist || (job?.stateRegion === "Singapore" && isMobile) ? (
                  <BoxContainer
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"100%"}
                  >
                    <BoxContainer gap={"0.5rem"}>
                      {isMobile && job?.stateRegion === "Singapore" && (
                        <>
                          <SharedSGTooltip
                            showWhenTap
                            placement={"bottom-start"}
                          />
                          {chipExist ? (
                            <DividerStyled orientation="vertical" />
                          ) : null}
                        </>
                      )}
                      {job?.boosted ? (
                        <HighlightChip>
                          {isClient && <HighlightIcon />}
                        </HighlightChip>
                      ) : null}
                      {job?.spotlight || jobSlotUpgrade ? (
                        <SpotlightChip>
                          {isClient && <SpotlightIcon />}
                        </SpotlightChip>
                      ) : null}
                      {job?.globalHirePreferences?.rightToWorkOpenToSponsor ? (
                        <GlobalChip>
                          {isClient && <GlobalIcon />}
                          Global Hire
                        </GlobalChip>
                      ) : null}
                    </BoxContainer>
                    {job?.category === "aggregated" ? null : (
                      <ButtonGroup
                        job={job}
                        origin={origin}
                        currentId={currentId}
                        onFollowJobClicked={onFollowJobClicked}
                        unSaveJob={unSaveJob}
                      />
                    )}
                  </BoxContainer>
                ) : null}
                <BoxContainer
                  flexDirection={isMobile ? "column" : "row"}
                  gap={isMobile ? "0.5rem" : "0"}
                  position={"relative"}
                >
                  {/* LEFT SIDE - JOB TITLE, SALARY AND LOCATION */}
                  <BoxContainer
                    flexDirection={isMobile ? "row" : "column"}
                    gap={"0.625rem"}
                    width={isMobile ? "100%" : "424px"}
                    minWidth={isMobile ? "100%" : "424px"}
                  >
                    <InView
                      style={{ width: "100%" }}
                      onChange={(inView) =>
                        inView === true && trackImpressionInView(job, index)
                      }
                    >
                      <JobTitleStyled variant={"h2"}>
                        {job?.title}
                      </JobTitleStyled>
                    </InView>

                    {isMobile &&
                    !chipExist &&
                    job?.stateRegion !== "Singapore" ? (
                      job?.category === "aggregated" ? null : (
                        <ButtonGroup
                          job={job}
                          origin={origin}
                          currentId={currentId}
                          onFollowJobClicked={onFollowJobClicked}
                          unSaveJob={unSaveJob}
                        />
                      )
                    ) : null}

                    {!isMobile ? (
                      <BoxContainer gap={"0.625rem"} marginLeft={"-0.3rem"}>
                        <BoxContainer
                          alignItems={"center"}
                          gap={"0.5rem"}
                          width={"44%"}
                        >
                          {isClient && <DollarIcon />}
                          <TextStyled
                            variant={"h4"}
                            color={"rgba(0, 0, 0, 0.60)"}
                            fontSize={"14px"}
                            fontWeight={"400"}
                            lineHeight={"1.25rem"}
                          >
                            {job?.salary === "Undisclosed" ||
                            job?.salary === "Undefined"
                              ? "Undisclosed"
                              : formatSalary(job?.salary, job?.crossBorderData)}
                          </TextStyled>
                        </BoxContainer>
                        <BoxContainer alignItems={"center"} gap={"0.5rem"}>
                          {isClient && <LocationIcon />}
                          <TextStyled
                            variant={"h4"}
                            color={
                              job?.stateRegion === "Singapore"
                                ? Color.reject
                                : "rgba(0, 0, 0, 0.60)"
                            }
                            fontSize={"14px"}
                            fontWeight={"400"}
                            lineHeight={"1.25rem"}
                          >
                            {job?.stateRegion}
                          </TextStyled>
                        </BoxContainer>
                      </BoxContainer>
                    ) : null}
                  </BoxContainer>

                  {/* RIGHT SIDE - COMPANY LOGO AND COMPANY NAME */}
                  <BoxContainer
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                    gap={"10px"}
                    width={isMobile ? "100%" : "100%"}
                  >
                    <BoxContainer
                      alignItems={isMobile ? "center" : "flex-start"}
                      gap={isMobile ? "0.5rem" : "1rem"}
                      maxWidth={"100%"}
                    >
                      <CustomTooltip
                        followCursor
                        title={"Click to view company profile"}
                      >
                        {isClient && (
                          <CompanyLogoStyled
                            src={companyLogo}
                            alt={companyDetails?.name + "logo"}
                            loading={"lazy"}
                            sx={{
                              objectFit: companyDetails?.logo
                                ? "contain"
                                : "fit",
                            }}
                            onClick={(event) =>
                              handleClickLogo(event, companyDetails?.slug)
                            }
                          />
                        )}
                      </CustomTooltip>

                      <CompanyNameStyled variant={"h3"}>
                        {companyName}
                      </CompanyNameStyled>
                    </BoxContainer>
                    {!isMobile && !chipExist ? (
                      job?.category === "aggregated" ? null : (
                        <ButtonGroup
                          job={job}
                          origin={origin}
                          currentId={currentId}
                          onFollowJobClicked={onFollowJobClicked}
                          unSaveJob={unSaveJob}
                        />
                      )
                    ) : null}
                  </BoxContainer>
                </BoxContainer>
                {isMobile ? (
                  <>
                    <MobileChipContainer>
                      <MobileDetailChip type={"jobType"}>
                        {job?.jobType}
                      </MobileDetailChip>
                      <MobileDetailChip type={"state"}>
                        {job?.stateRegion}
                      </MobileDetailChip>
                      <MobileDetailChip type={"salary"}>
                        {job?.salary === "Undisclosed" ||
                        job?.salary === "Undefined"
                          ? "Undisclosed"
                          : formatSalary(job?.salary, job?.crossBorderData)}
                      </MobileDetailChip>
                    </MobileChipContainer>
                    <BoxContainer justifyContent={"flex-end"}>
                      <PostedTextStyled
                        $jobSlotMax={job?.jobSlotType === "job_slot_max"}
                      >
                        {jobPostedTime}
                      </PostedTextStyled>
                    </BoxContainer>
                  </>
                ) : null}
              </TopContainer>

              {isMobile &&
              job?.keywordHighlight &&
              job?.keywordHighlightText?.length > 0 ? (
                <MobileKeywordContainer
                  $jobSlotMax={job?.jobSlotType === "job_slot_max"}
                >
                  {job?.keywordHighlightText?.map((jobPerks, index) => {
                    return (
                      <KeywordChip
                        key={index}
                        $jobSlotMax={job?.jobSlotType === "job_slot_max"}
                      >
                        {jobPerks}
                      </KeywordChip>
                    );
                  })}
                </MobileKeywordContainer>
              ) : null}

              <BottomContainer
                $jobSlotMax={job?.jobSlotType === "job_slot_max"}
              >
                {isMobile ? (
                  <MobileApplyButton
                    disabled={
                      job?.haveApplied || job?.haveApplied === undefined
                    }
                    onClick={(event) => handleApplyJob(event, job, reduxUser)}
                  >
                    {job?.haveApplied || job?.haveApplied === undefined ? (
                      "applied"
                    ) : job?.category === "scraped" ||
                      job?.category === "aggregated" ? ( // Later need to put checking for external job
                      <BoxContainer alignItems={"center"} gap={"0.375rem"}>
                        {"Apply"}

                        {isLoggedIn ? (
                          <BoxContainer alignItems={"center"} gap={"0.375rem"}>
                            {"Apply"}
                            {isLoggedIn && isClient && <ArrowIconStyled />}
                          </BoxContainer>
                        ) : null}
                      </BoxContainer>
                    ) : (
                      "apply now"
                    )}
                  </MobileApplyButton>
                ) : (
                  <>
                    <BoxContainer alignItems={"center"} gap={"0.5rem"}>
                      {job?.stateRegion === "Singapore" && (
                        <>
                          <SharedSGTooltip
                            showWhenTap={!isDesktop ? true : false}
                            placement={"bottom-start"}
                          />
                          <DividerStyled
                            orientation="vertical"
                            $jobSlotMax={job?.jobSlotType === "job_slot_max"}
                          />
                        </>
                      )}
                      <JobTypeChip
                        $jobSlotMax={job?.jobSlotType === "job_slot_max"}
                      >
                        {job?.jobType}
                      </JobTypeChip>
                      {job?.keywordHighlight &&
                      job?.keywordHighlightText?.length > 0 ? (
                        <>
                          <DividerStyled
                            orientation="vertical"
                            $jobSlotMax={job?.jobSlotType === "job_slot_max"}
                          />
                          <BoxContainer
                            alignItems={"center"}
                            flexWrap={"wrap"}
                            gap={"0.375rem"}
                          >
                            {job?.keywordHighlightText?.map(
                              (jobPerks, index) => {
                                return (
                                  <KeywordChip
                                    key={index}
                                    $jobSlotMax={
                                      job?.jobSlotType === "job_slot_max"
                                    }
                                  >
                                    {jobPerks}
                                  </KeywordChip>
                                );
                              }
                            )}
                          </BoxContainer>
                        </>
                      ) : null}
                    </BoxContainer>
                    <BoxContainer alignItems={"center"} gap={"0.375rem"}>
                      <PostedTextStyled
                        $jobSlotMax={job?.jobSlotType === "job_slot_max"}
                      >
                        {getTime(job) !== null ? getTime(job) : null}
                      </PostedTextStyled>
                    </BoxContainer>
                  </>
                )}
              </BottomContainer>
            </InnerWrapperContainer>
          </CustomTooltip>
          {isDesktop ? (
            <CustomTooltip title={"Click here to apply!"} followCursor>
              <Greenthang
                variant="contained"
                className="apply"
                onClick={handleApplyButtonClick}
                onMouseEnter={() => setHoverApplyButton(true)}
                onMouseLeave={() => setHoverApplyButton(false)}
                disabled={
                  applyingJob
                    ? true
                    : job?.haveApplied || job?.haveApplied === undefined
                    ? true
                    : false
                }
                loading={applyingJob}
              >
                <BoxContainer
                  flexDirection={"column"}
                  alignItems={"center"}
                  gap={"0.325rem"}
                >
                  {/* loading logic should take higher precedence than hover logic */}
                  {job?.haveApplied
                    ? "Applied"
                    : hoverApplyButton
                    ? "Apply Now!"
                    : "Click here to apply!"}
                  {job?.category === "scraped" ||
                  job?.category === "aggregated" ? (
                    isLoggedIn ? (
                      <BoxContainer alignItems={"center"} gap={"0.375rem"}>
                        {"Apply"}
                        {isLoggedIn && isClient && <ArrowIconStyled />}
                      </BoxContainer>
                    ) : null
                  ) : null}
                </BoxContainer>
              </Greenthang>
            </CustomTooltip>
          ) : null}
        </InnerWrapperContainer>
      </JobCardContainer>
    </LinkContainer>
  );
}

function ButtonGroup(props) {
  const { job, currentId, onFollowJobClicked, unSaveJob } = props;
  const dispatch = useDispatch();
  const isClient = useIsClient();

  const bookmarkingJob = useSelector((state) => state?.jobs?.bookmarkingJob);
  const unbookmarkJob = useSelector((state) => state?.jobs?.unbookmarkJob);

  function onTriggerSaveJob(event, job) {
    event.stopPropagation();
    event.preventDefault();

    if (!checkAuthentication()) {
      return dispatch(updateSelectedJob(job)).then(() => {
        dispatch(updateSignUpOrigin("save_job")).then(() => {
          dispatch(updateShowSignInModalStatus(true));
        });
      });
    }

    if (!job?.bookmark) {
      isSavingJob = true;
      if (onFollowJobClicked) {
        onFollowJobClicked(job?.id)
          .then((response) => {
            isSavingJob = false;
          })
          .catch((error) => {
            isSavingJob = false;
          });
      } else {
        isSavingJob = false;
      }
    } else {
      isSavingJob = true;
      if (unSaveJob) {
        unSaveJob(job?.id)
          .then((response) => {
            isSavingJob = false;
          })
          .catch((error) => {
            isSavingJob = false;
          });
      } else {
        isSavingJob = false;
      }
    }
  }

  return (
    <BoxContainer
      justifyContent={"flex-end"}
      alignItems={"center"}
      gap={"0.625rem"}
    >
      <BookmarkButton onClick={(event) => onTriggerSaveJob(event, job)}>
        {job?.bookmark
          ? unbookmarkJob && currentId === job?.id
            ? isClient && <CircularProgressStyled />
            : isClient && <SaveFilledIcon />
          : bookmarkingJob && currentId === job?.id
          ? isClient && <CircularProgressStyled />
          : isClient && <SaveOutlineIcon />}
      </BookmarkButton>
    </BoxContainer>
  );
}

export default AccordionJobCard;
