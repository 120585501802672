import { useIsClient } from "@uidotdev/usehooks";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formJobQueryParam } from "../../../functions/job";
import { calibrateSalary } from "../../../helpers/data_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import {
  clearJobList,
  getExperienceLevels,
  getJobs,
  getJobsParams,
  getSpecialisation,
  getStateRegions,
  updateJobListPage,
  updateLoadingJobs,
} from "../../../redux/actions/job_action";
import { store } from "../../../redux/stores/store";
import * as jobTypes from "../../../redux/types/job_type";
import SharedNavbar2 from "../../shared/SharedNavbar/Navbar/Navbar";
import AccordionJobList from "../AccordionJobList/AccordionJobList";

import "react-toastify/dist/ReactToastify.css";

function NewJobListWrapper(props) {
  const { serverJobs } = props;
  const [renderedJobList, setRenderedJobList] = useState(serverJobs);

  const pageParams = null;

  const dispatch = useDispatch();
  const isClient = useIsClient();

  const firstTimeMountLoadingJobs = useRef(true);

  const jobList = useSelector((state) => state.jobs?.jobs);

  // Filter items
  const specialisationList = useSelector((state) => state.jobs?.tracks);
  const jobTypeList = useSelector((state) => state.jobs?.jobTypes);
  const experienceLevelList = useSelector(
    (state) => state.jobs?.experienceLevels
  );

  // Applied filters items
  const jobListFilter = useSelector((state) => state.jobs?.jobListFilter);
  const searchType = useSelector((state) => state.jobs.searchType);

  /**
   * Fetches job listings with optional refresh functionality
   *
   * This function handles:
   * 1. Job data fetching with pagination
   * 2. Loading state management
   * 3. Analytics tracking
   * 4. GraphQL request cancellation
   * 5. Redux state updates
   */

  function fetchJobs(refresh = false) {
    // Prevent concurrent fetches unless explicitly refreshing
    if (store.getState().jobs.isLoadingJobs && !refresh) return;

    // Set loading state in Redux
    dispatch(updateLoadingJobs(true));

    // Cancel any in-flight GraphQL requests to prevent race conditions
    if (store.getState().axios.cancelTokens[jobTypes.FETCHING_JOBS_KEY]) {
      store
        .getState()
        .axios.cancelTokens[jobTypes.FETCHING_JOBS_KEY].cancel(
          "job list search"
        );
    }

    // Generate unique key to track current request
    const currentLoadingJobsKey = new Date().getTime();
    store.getState().jobs.currentLoadingJobsKey = currentLoadingJobsKey;

    // Build query parameters from current filter state
    const params = formJobQueryParam(jobListFilter, refresh);

    // Configure pagination settings
    const pagination = {
      first: 30, // Number of items per page
      last: null,
      startCursor: pageParams ?? null, // Current page cursor
      endCursor: null, // Next page cursor
    };

    // Handle analytics tracking (skipped on first mount)
    if (firstTimeMountLoadingJobs.current === false) {
      // Process state/region data for analytics
      const preProcessStateRegion =
        params?.stateRegions && Array.isArray(params?.stateRegions)
          ? params.stateRegions.join(", ").length !== 0
            ? params.stateRegions
            : null
          : null;

      // Process experience levels
      const regex = /\d+(\.\d+)?/g;
      const selectedExp =
        experienceLevelList
          .filter((exp) => params?.experienceIds?.includes(parseInt(exp.id)))
          .map((match) =>
            match.title.toLowerCase().replace(/intern|fresh graduate/g, "0")
          ) ?? [];

      const cleanSelectExp = selectedExp.join().match(regex)?.sort() ?? [];

      // Check if any filters are applied
      const hasFilters = !!(
        params?.trackIds?.length > 0 ||
        params?.stateRegions?.length > 0 ||
        params?.jobTypeIds?.length > 0 ||
        params?.experienceIds?.length > 0 ||
        params?.expectedSalary > 0 ||
        params?.globalHire
      );

      // Send analytics event with detailed search parameters
      sendTrackingEvent({
        event: "CE_job_search_jlp",
        "search-term":
          params.keyword && params.keyword !== "*" ? params.keyword : null,
        has_filters: hasFilters,
        input_type: searchType,
        specialisation: params.trackIds ?? null,
        states: preProcessStateRegion ?? null,
        "job-type": params.jobTypeIds ?? null,
        experience: params.experienceIds ?? null,
        salary:
          params.expectedSalary &&
          Number.isInteger(params.expectedSalary) &&
          params.expectedSalary > 0
            ? calibrateSalary(params.expectedSalary)
            : null,
        "ct-specialization":
          specialisationList
            .filter((track) => params?.trackIds?.includes(parseInt(track.id)))
            .map((match) => match.slug) ?? [],
        "ct-job-types":
          jobTypeList
            .filter((type) => params?.jobTypeIds?.includes(parseInt(type.id)))
            .map((match) => match.title) ?? [],
        "ct-min-exp": parseInt(cleanSelectExp[0] ?? 0) ?? 0,
        "ct-max-exp":
          parseInt(cleanSelectExp[cleanSelectExp.length - 1] ?? 0) ?? 0,
      });
    } else {
      // Mark first mount as complete
      firstTimeMountLoadingJobs.current = false;
    }

    // Store current search parameters in Redux
    dispatch(getJobsParams(params));

    // Update pagination state in Redux
    if (pageParams) {
      const currentPage = Number(atob(pageParams)) / 30;
      dispatch(updateJobListPage(currentPage));
    } else {
      dispatch(updateJobListPage(1));
    }

    // Handle job list fetching with optional clearing
    const fetchAction = refresh
      ? dispatch(clearJobList()).then(() =>
          dispatch(getJobs(params, pagination))
        )
      : dispatch(getJobs(params, pagination));

    // Return promise chain with proper error handling
    return fetchAction
      .then((response) => {
        if (response.type === "FETCH_JOBS_SUCCEED") {
          triggerSnackbarFunc({
            snackbarMessage: "Your page is now reset to the default search",
            severity: "ashley",
          });
        }
        store.getState().jobs.isLoadingJobs = false;
        return response;
      })
      .catch((error) => {
        store.getState().jobs.isLoadingJobs = false;
        throw error;
      });
  }

  useEffect(() => {
    if (isClient && Object.keys(jobList).length > 0) {
      setRenderedJobList(jobList);
    }
  }, [isClient, jobList]);

  useEffect(() => {
    fetchJobs();

    dispatch(getSpecialisation());
    dispatch(getStateRegions());
    dispatch(getExperienceLevels());
  }, [dispatch]);

  return (
    <Fragment>
      <SharedNavbar2 page={"job-list-page"} />
      <AccordionJobList jobs={renderedJobList} />
    </Fragment>
  );
}

export default NewJobListWrapper;
