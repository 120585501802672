import { decode } from "html-entities";
import { store } from "../redux/stores/store";
import * as types from "../redux/types/job_type";

const clearAndFetchJobs = (updateJobId, clearJobList, getJobs, startCursor) => {
  if (store.getState().jobs.isLoadingJobs) return;

  store.getState().jobs.isLoadingJobs = true;

  //Cancel any existing call to graphQL
  if (store.getState().axios.cancelTokens[types.FETCHING_JOBS_KEY]) {
    store
      .getState()
      .axios.cancelTokens[types.FETCHING_JOBS_KEY].cancel("job search navbar");
  }

  updateJobId("");

  clearJobList().then((clearResponse) => {
    let params = formJobQueryParam(store.getState().jobs.jobListFilter, true);

    const pagination = {
      first: null,
      last: null,
      startCursor: null,
      endCursor: null,
    };

    getJobs(params, pagination)
      .then((response) => {
        store.getState().jobs.isLoadingJobs = false;
        store.getState().jobs.jobCurrentPage = 1;
      })
      .catch((error) => {
        store.getState().jobs.isLoadingJobs = false;
        store.getState().jobs.jobCurrentPage = 1;
      });
  });
};

const formJobQueryParam = (jobFilter, refresh) => {
  let currentLoadingJobsKey = new Date().getTime();
  store.getState().jobs.currentLoadingJobsKey = currentLoadingJobsKey;

  let params = {
    refresh: refresh,
    page: refresh ? 1 : store.getState().jobs.jobCurrentPage,
    canCancel: false,
    currentLoadingJobsKey: currentLoadingJobsKey,
    cancelTokenKey: types.FETCHING_JOBS_KEY,
    expectedSalary: jobFilter["expectedSalary"]
      ? jobFilter["expectedSalary"]
      : 0,
  };

  if (jobFilter["keyword"] && jobFilter["keyword"] != "") {
    params["keyword"] = jobFilter["keyword"];
  } else {
    params["keyword"] = "*";
  }

  if (jobFilter["trackIds"]?.length > 0) {
    params["trackIds"] = jobFilter["trackIds"];
  }

  if (jobFilter["stateRegions"]?.length > 0) {
    params["stateRegions"] = jobFilter["stateRegions"];
  }

  if (jobFilter["jobTypeIds"]?.length > 0) {
    params["jobTypeIds"] = jobFilter["jobTypeIds"];
  }

  if (jobFilter["experienceIds"]?.length > 0) {
    params["experienceIds"] = jobFilter["experienceIds"];
  }

  if (jobFilter["company_ids"]?.length > 0) {
    params["companyIds"] = jobFilter["company_ids"];
  }

  if (jobFilter["operator"] && jobFilter["operator"] != "") {
    params["operator"] = jobFilter["operator"];
  } else {
    params["operator"] = "and";
  }

  if (jobFilter["globalHire"] && jobFilter["globalHire"] != "") {
    params["globalHire"] = jobFilter["globalHire"];
  }

  return params;
};

const formJobSuggestionQueryParam = (jobFilter, refresh) => {
  let currentSuggestionKey = new Date().getTime();
  store.getState().jobs.currentSuggestionKey = currentSuggestionKey;

  let params = {
    refresh: refresh,
    page: refresh
      ? 1
      : store.getState().jobs.suggestionPage
      ? store.getState().jobs.suggestionPage
      : 1,
    canCancel: false,
    currentSuggestionKey: currentSuggestionKey,
    cancelTokenKey: types.FETCH_JOB_SUGGESTION_KEY,
    expectedSalary: jobFilter["expected_salary"]
      ? jobFilter["expected_salary"]
      : 0,
  };

  if (jobFilter["keyword"] && jobFilter["keyword"] != "") {
    params["keyword"] = jobFilter["keyword"];
  } else {
    params["keyword"] = "*";
  }

  if (jobFilter["track_ids"]?.length > 0) {
    params["trackIds"] = jobFilter["track_ids"];
  }

  if (jobFilter["state_region_names"]?.length > 0) {
    params["stateRegions"] = jobFilter["state_region_names"];
  }

  if (jobFilter["job_type_ids"]?.length > 0) {
    params["jobTypeIds"] = jobFilter["job_type_ids"];
  }

  if (jobFilter["experience_ids"]?.length > 0) {
    params["experienceIds"] = jobFilter["experience_ids"];
  }

  return params;
};

function convertGptSummary(job) {
  if (!job?.gptSummary) return "";

  let regexHex = /&#x([0-9a-fA-F]+);/g;

  let result = job.gptSummary.replace(regexHex, (_, hexValue) => {
    let char = String.fromCharCode(parseInt(hexValue, 16));
    return char;
  });

  return decode(result);
}

function truncateSkills(job) {
  if (!job?.skills?.length) return "";

  let skillsString = job.skills.map((skill) => skill.name).join(", ");
  if (skillsString.length <= 70) return { fullString: skillsString };

  let truncated = skillsString.slice(0, 70).trim();
  let lastCommaIndex = truncated.lastIndexOf(",");
  if (lastCommaIndex > 0) {
    truncated = truncated.slice(0, lastCommaIndex);
  }
  // Count displayed items by matching the truncated string with original skills
  const displayedSkills = job.skills.filter((skill) =>
    truncated.includes(skill.name)
  ).length;
  const remainingCount = job.skills.length - displayedSkills;

  return {
    fullString: skillsString,
    truncated: truncated + "...",
    remainingCount: remainingCount,
  };
}

function truncateBenefits(job) {
  if (!job?.company?.benefits?.length) return "";

  let benefitsString = job?.company?.benefits
    ?.map((benefit) => benefit?.title)
    .join(", ");
  if (benefitsString.length <= 70) return { fullString: benefitsString };

  let truncated = benefitsString.slice(0, 70).trim();
  let lastCommaIndex = truncated.lastIndexOf(",");
  if (lastCommaIndex > 0) {
    truncated = truncated.slice(0, lastCommaIndex);
  }
  // Count displayed items by matching the truncated string with original benefits
  const displayedBenefits = job.company.benefits.filter((benefit) =>
    truncated.includes(benefit.title)
  ).length;
  const remainingCount = job.company.benefits.length - displayedBenefits;

  return {
    fullString: benefitsString,
    truncated: truncated + "...",
    remainingCount: remainingCount,
  };
}

export {
  clearAndFetchJobs,
  convertGptSummary,
  formJobQueryParam,
  formJobSuggestionQueryParam,
  truncateBenefits,
  truncateSkills,
};
